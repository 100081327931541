import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/storage";
import "firebase/compat/database";
import "firebase/compat/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyC9rGWqh2YhsjXC_Le333bbJr-7H9lAWdQ",
  authDomain: "messaging-app-mern-5fc1e.firebaseapp.com",
  projectId: "messaging-app-mern-5fc1e",
  storageBucket: "messaging-app-mern-5fc1e.appspot.com",
  messagingSenderId: "494708692036",
  appId: "1:494708692036:web:be268e44b29fcf3e905811",
  measurementId: "G-4GEHHZV68E",
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();
const auth = firebase.auth();
const provider = new firebase.auth.GoogleAuthProvider();

export { auth, provider };
export default db;
